import { Injectable } from '@angular/core';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { User } from './user';
import { Observable, throwError, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

const localUrl = 'assets/data/';
//const apiUrl = 'https://ingeredes.net/api/';
const apiUrl = 'https://api.erpcloudservice.com/';

@Injectable({
  providedIn: 'root'
})
export class ErpService {
  public user: User;
  public leftMenu: any;
  public rightMenu: any;
  public isMobile: boolean;
  public headers: any;
  constructor(
    private router : Router,
    private storage: Storage,
    private platform: Platform,
    private http: HttpClient,
    private httpMobile: HTTP, 
    private translate: TranslateService
  ) {
    this.rightMenu = {actions:[]};
    let lang = this.translate.getDefaultLang();
    /** Define los encabezados HTTP */
    this.headers = new HttpHeaders();
    //this.headers = this.headers.append("Referrer-Policy", "no-referrer-when-downgrade");
    //this.headers = this.headers.append("Content-Type", "application/json; charset=utf-8");
    this.headers = this.headers.append("Content-Type", "application/x-www-form-urlencoded");
    this.headers = this.headers.append("Access-Control-Allow-Origin", "*");
    this.headers = this.headers.append("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    this.headers = this.headers.append("Access-Control-Allow-Headers", "Access-Control-Allow-Headers, Origin, Referer, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers");
    this.headers = this.headers.append("Accept", 'application/json');
    this.headers = this.headers.append("Language", lang);
    this.isMobile = (this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb');
    console.log('IS MOBILE?', (this.isMobile)? 'YES': 'NO', this.platform.platforms(), lang);
  }

  /** Métodos utilizados por las funciones */
  get(url): Observable<any> {
    if(this.isMobile){
      return from(this.httpMobile.get(url, {}, {}).then(resp => {return JSON.parse(resp.data);}).catch(error => {return error.error;}));
    }
    else {
      console.log('NO ES MÓVIL!!!')
      return this.http.get<any>(url,this.headers).pipe(catchError((error:Response) => {return this.errorHandler(error)}));
    }
  } 
  
  post(url, data): Observable<any> {
    if(this.isMobile){
      return from(this.httpMobile.post(url, data, {}).then(resp => {return JSON.parse(resp.data);}).catch(error => {return error.error;}));
    }
    else {
      //let options = { headers: this.headers };
      //return this.http.post<any>(url, data, options).pipe(catchError((error:Response) => {return this.errorHandler(error)}));
      return this.http.post<any>(url, data).pipe(catchError((error:Response) => {return this.errorHandler(error)}));
    }
  } 
  
  /** Manejo de errores */
  errorHandler(error) {
    if(error.status === 404) {
      return throwError("Resource Not Found.");
    }
    return throwError(JSON.stringify(error) || "server error.");
  }

  /** Funciones del ERP hacia la API */
  getImage(code: string, format: string, enterprise: string, lang: string = 'es'): Observable<any> {
    return this.get(apiUrl+'images/'+format+'/'+enterprise+'/'+code+'/'+lang);
  }

  getFlag(code: string): Observable<any> {
    return this.get(apiUrl+'flags/'+code);
  }
  getInstance(element: string, enterprise: string, id: number, lang: string = 'es'): Observable<any> {
    return this.get(apiUrl+'instance/'+element+'/'+enterprise+'/'+id+'/'+lang);
  }

  getInstances(element, filter = null){
      return new Promise((resolve, reject) => {
      this.http.get(localUrl+element+'.json').subscribe((data:Array<any>) => {
        if(!filter) resolve({response: true, message:'Elementos='+element+', Encontrados '+data.length, items: data});
        else {
          resolve({response: true, message:'Elementos='+element+', Encontrados '+data.length, items: data.filter(x=>{
            return x[filter.field] === filter.value;
          })});
        }
      }, err=>{
        reject({response: false, message: err, item:null});
      });
    });
  }

  getItems(_module, _section, _id = null, _filter = null, lang: string = 'es'): Observable<any> {
    if(!_module || !_section) return null;
    return this.get(apiUrl+'items/'+_module+'/'+_section+'/'+lang);
  }

  /* getContent(_data:any, _module:string, _section:string, _page:string = null): Observable<any> {
    if(!_module || !_section) return null;
    if(_page === null) _page = 'main';
    console.log('SEND TO: ErpService :: getContent =>', apiUrl+'content/'+_module+'/'+_section+'/'+_page, JSON.stringify(_data));
    return this.post(apiUrl+'content/'+_module+'/'+_section+'/'+_page, _data);
  } */

  getContent(_enterprise:any, _module:string, _section:string, _page:string = null): Observable<any> {
    if(!_module || !_section) return null;
    if(_page === null) _page = 'main';
    console.log('SEND TO: ErpService :: getContent =>', apiUrl+'content/'+_module+'/'+_section+'/'+_page);
    return this.get(apiUrl+'content/'+_enterprise+'/'+_module+'/'+_section+'/'+_page);
  }
  
  getItemsList(list:string, enterprise: string, fields: string = '*', from: string = '_', where: string = '_', order: string = '_', limit: string = '_', page: number = 0, lang: string = 'es'): Observable<any> {
    return this.get(apiUrl+'list/'+list+'/'+enterprise+'/'+fields+'/'+from+'/'+where+'/'+order+'/'+limit+'/'+page+'/'+lang);
  }

  getModuleDetails(module: string, enterprise: string, lang: string = 'es'): Observable<any> {
    if(module === '' || !module) return null;
    return this.get(apiUrl+'moduleInfo/'+module+'/'+enterprise+'/'+lang);
  }

  getModules(user: User, lang: string = 'es'): Observable<any> {
    if(!user.userCode || !user.userRoleId) return null;
    console.log(user, apiUrl+'modules/'+user.userCode+'/'+user.userRoleId+'/'+lang);
    return this.get(apiUrl+'modules/'+user.userCode+'/'+user.userRoleId+'/'+lang);
  }

  getModulesDetails(enterprise: string, page: number = 0, lang: string = 'es'): Observable<any> {
    return this.get(apiUrl+'modulesInfo/'+enterprise+'/'+page+'/'+lang);
  }

  getPlans(_module:string, lang: string = 'es'): Observable<any> {
    if(_module === '' || !_module) return null;
    return this.get(apiUrl+'plans/'+_module+'/'+lang);
  }

  getSections(_code:string, user = new User(), lang:string = 'es'): Observable<any> {
    if(!user.userCode || !user.userRoleId || _code =='') return null;
    return this.get(apiUrl+'sections/'+_code+'/'+user.userCode+'/'+user.userRoleId+'/'+lang);
  }

  login(username:string, password:string, lang: string = 'es'): Observable<any> {
    return this.get(apiUrl+'login/'+username+'/'+password+'/'+lang);
    //return this.post(apiUrl+'login/', {username: username, password: password});
    /* let url = apiUrl+'login/';
    let data = {username: username, password: password};
    if(this.isMobile){
      return from(this.httpMobile.post(url, data, {}).then(resp => {return JSON.parse(resp.data);}).catch(error => {return error.error;}));
    }
    else {
      return this.http.post<any>(url, data).pipe(catchError((error:Response) => {return this.errorHandler(error)}));
    } */
  }

  register(value:any, lang: string = 'es'): Observable<any> {
    return this.post(apiUrl+'register/'+lang, value);
  }

  recovery(value:any, lang: string = 'es'): Observable<any> {
    return this.post(apiUrl+'recovery/'+lang, value);
  }

  validate(value:any, lang: string = 'es'): Observable<any> {
    return this.post(apiUrl+'validate/'+lang, value);
  }

  country(value:any): Observable<any> {
    return this.get(apiUrl+'country/'+value);
  }

  countries(): Observable<any> {
    return this.get(apiUrl+'countries');
  }

  users(): Observable<any> {
    return this.get(apiUrl+'users');
  }

  /** Funciones del ERP en el Storage local */
  async getUser() {
    return await this.storage.get('user');
  }
  
  logout(url:string = ''):any {
    return new Promise((resolve, reject) => {
      this.storage.set('user', null).then((result)=>{
        console.log('ERP Service: Logout: ', 'Result:', result);
        if(url !== '') this.router.navigate([url], {replaceUrl: true});
      });  // Guarda la información del user en el Local Store
      resolve(new User());
    });
  }
  
  async profile(user:User, url:string = '') {
    return await this.storage.set('user', user).then((result)=>{
      console.log('ERP Service: SAVE Profile: ', result, url);
      if(url !== '') this.router.navigate([url]);
      return true;
    });  // Guarda la información del user en el Local Store
  }

  getLeftMenu(){
    console.log('ERP Service: getLeftMenu', this.leftMenu);
    return this.leftMenu;
  }

  /** Otras funciones auxiliares */
  getRightMenu(){
    console.log('ERP Service: getRightMenu', this.rightMenu);
    return this.rightMenu;
  }

  selectSection(section){
    this.leftMenu.sectionSelected = section;
    this.leftMenu.sectionTitle = this.leftMenu.sections[section].title;
    console.log('ERP Service: selectSection', this.leftMenu, section);
    return this.leftMenu;
  }

  selectTop(user) {
    console.log('==> ERPService :: selectTop->', user);
    this.getModules(user).subscribe((result: any)=>{
      console.log('==> ERPService :: selectTop->getModules', result.modules);
      let _menu = {
        visible: true,
        closeIcon: '',
        sectionsVisible: false,
        sectionsLabel: '',
        sectionTitle: '',
        sectionSelected: -1,
        sections:[],
        menuRight: false,
        modulesVisible: true,
        modulesCollapsed: false,
        moduleSelected: -1,
        modulesTitle: 'Módulos',
        moduleTitle: '',
        modules: (result.modules !== null)? result.modules:[]
      };
      return _menu;
/*     }).catch((err)=>{
      console.error('ERROR ==> ERPService :: selectTop->', err); */
    });
  }
/* 
  getResults(keyword:string):Observable<any[]> {
    let observable:Observable<any>;

    if (this.countries.length === 0) {
      observable = this.http.get('https://restcountries.eu/rest/v2/all');
    } else {
      observable = of(this.countries);
    }

    return observable.pipe(
      map(
        (result) => {
          return result.filter(
            (item) => {
              return item.name.toLowerCase().startsWith(
                  keyword.toLowerCase()
              );
            }
          );
        }
      )
    );
  } */
/* 
  testServer(ip){
    return new Promise((resolve, reject) => {
      let testUrl = '';
      for(let n=0; n<ip.length; n++){
        testUrl = testUrl + '.' + ip[n];
      }
      testUrl = testUrl.substr(1);
      console.log('Probando IP:'+testUrl);
      this.http.get('http://'+testUrl+'/api/version').subscribe((data) => {
        resolve({response:true, data:data});
      }, err=>{
        reject({response: false, message: err, item:null});
      });
    });
  }
 */
}
