import { User } from './user';
import { Image } from './image';
import { Module } from './module';

export class Erp{
    /**
     * Define las constantes con los valores por defecto del sistema
     */

    static readonly _version: string = '1.0.0';             // Versión del ERP
    static readonly _defLanguage: string = 'es';            // Idioma por defecto del ERP
    static readonly _defDarkMode: boolean = false;          // Modo Nocturno por defecto del ERP
    static readonly _defTimeZone: string = 'VET';           // Huso Horario por defecto del ERP
    static readonly _defPrimaryColor: string = '#6ECB6D';   // Color primario por defecto del ERP
    static readonly _defSecretCodeTimeout: number = 120;    // Tiempo en segundos de validez del código secreto para reestablecer contraseña

    constructor(
        private ready: boolean = false,             // ERP listo
        //public darkMode: boolean = false,           // Modo oscuro de la aplicación
        private user: User = new User(),            // Usuario del sistema
        private moduleCode: string = null,          // Módulo seleccionado en la ruta
        private sectionCode: string = null,         // Sección seleccionada en la ruta
        private idCode: string = null,              // Id seleccionado en la ruta
        private module: Module = null,              // Módulo seleccionado por el usuario
        private modules: Array<any> = [],           // Módulos disponibles para el perfil del usuario
        private sections: Array<any> = [],          // Secciones disponibles para el perfil del usuario
        private closeIcon: string = '',             // Ícono para abrir el menú ('' = Hamburguesa)
        private sectionsVisible: boolean = false,   // Muestra las secciones en el menú lateral
        private sectionsLabel: string = '',         // Etiqueta para identificar las secciones
        private sectionTitle: string = '',          // Título del grupo de las secciones
        private sectionSelected: number = -1,       // Índice de la sección seleccionada en el menú (-1 = ninguna)
        private menuRight: boolean = false,         // Menú derecho visible
        private modulesVisible: boolean = true,     // Muestra los módulos en el menú lateral
        private modulesCollapsed: boolean = false,  // Lista de módulos en el menú lateral colapsada
        private moduleSelected: number = -1,        // Índice del módulo seleccionado en el menú (-1 = ninguno)
        private modulesTitle: string = 'Módulos',   // Título del grupo de los módulos
        private moduleTitle: string = '',           // Título del módulo seleccionado
        private images: Array<Image> = [],          // Imágenes del sistema
        private moduleDetails: any = null,          // Módulo seleccionado para ver los detalles
        private tableSection: string = null,        // Tabla de la sección si es del tipo Element
        private noimage: string = '',
        private format: string = '',
        private infoModules: Array<any> = []
    ){
    }

    /**
     * Este método devuelve la versión del sistema ERP.
     */
    getSystemVersion(){
        return Erp._version;
    }

    /**
     * Este método devuelve el código ISO-2 del idioma por defecto del sistema.
     */
    getSystemLanguage(){
        return Erp._defLanguage;
    }

    /**
     * Este método devuelve bandera que indica si se activa modo nocturno por defecto en el sistema.
     */
    getSystemDarkMode(){
        return Erp._defDarkMode;
    }

    /**
     * Este método devuelve el código RGB hexadecimal del color primario por defecto del sistema.
     */
    getSystemPrimaryColor(){
        return Erp._defPrimaryColor;
    }

    /**
     * Este método devuelve el Huso Horario por defecto del sistema.
     */
    getSystemTimeZone(){
        return Erp._defTimeZone;
    }

    /**
     * Este método devuelve un true si el sistema está disponible o un false si aún no ha completado el proceso de inicialización.
     */
    isReady() {
        return this.ready;
    }
   
    /**
     * Este método devuelve un true si existe un usuario validado en el sistema (user !== null) o un false si aún no se ha validado ningún 
     * usuario (user === null).
     */
    isLoggedIn(){
        return this.user.loggedIn;
    }
    
    /**
     * Este método devuelve un true si existe un usuario validado y ha seleccionado un rol en el sistema (user !== null && user.role !== null) 
     * o un false si aún no se ha validado ningún usuario o si se ha validado pero aún no ha seleccionado ningún rol 
     * (user === null || user !== null && user.role !== null).
     */    
    isWorking(){
        return (this.user.loggedIn && this.user.userCode !== null);
    }

    /**
     * Este método devuelve la llave para la traducción del título de la aplicación en los diferentes idiomas.
     */
    getTitleKey(){
        return 'erpSystemTitle';
    }
    /**
     * Este método devuelve nombre del ícono si existe un menú lateral derecho o un false si no existe.
     */
    getRightIcon(){
        if(this.sectionSelected >= 0) return this.sections[this.sectionSelected].lateral;
        else return false;
    }
    
    /**
     * Este método devuelve el número de notificaciones a colocar en el ícono si existe un menú lateral derecho o un null si no existe el menú o 
     * no hay notificaciones.
     */
    getRightBadge(){
        return 0;
    }

    /**
     * Este método devuelve el código del usuario validado o un null si no se ha validado aún.
     */
    getUsername(){
        return this.user.username;
    }

    /**
     * Este método devuelve el nombre y el apellido del usuario validado o un null si no se ha validado aún.
     */
    getName(){
        if(!this.user.loggedIn) return null;
        return this.user.firstname +' '+this.user.lastname;
    }

    /**
     * Este método devuelve el nombre del usuario validado o un null si no se ha validado aún.
     */
    getFirstname(){
        if(!this.user.loggedIn) return null;
        return this.user.firstname;
    }

    /**
     * Este método devuelve el apellido del usuario validado o un null si no se ha validado aún.
     */
    getLastname(){
        if(!this.user.loggedIn) return null;
        return this.user.lastname;
    }

    /**
     * Este método devuelve el correo electrónico del usuario validado o un null si no se ha validado aún.
     */
    getEmail(){
        if(!this.user.loggedIn) return null;
        return this.user.userEmail;
    }

    /**
     * Este método devuelve el número de teléfono móvil del usuario validado o un null si no se ha validado aún. 
     * Si aún no se ha definido el número de teléfono móvil del usuario, devuelve un null.
     */
    getMobile(){
        if(!this.user.loggedIn) return null;
        return this.user.userMobile;
    }

    /**
     * Este método devuelve la foto del usuario validado o un null si no se ha validado aún. Si se ha validado no 
     * ha subido la foto de su perfil, devuelve la imagen de noPicture, noPictureMale o noPictureFemale, dependiendo 
     * de si se ha definido o no el género del usuario.
     */
    getPicture(){
        if(!this.user.loggedIn) return null;
        return this.user.userPicture;
    }

    /**
     * Este método devuelve el género del usuario validado o un null si no se ha validado aún. Si el usuario es del 
     * género masculino, devuelve una ‘M’, si es del género femenino devuelve una ‘F’ y si aún no se ha definido el 
     * género del usuario, devuelve un null.
     */
    getGender(){
        if(!this.user.loggedIn) return null;
        return this.user.userGender;
    }

    /**
     * Este método devuelve la edad en años del usuario validado o un null si no se ha validado aún. El valor devuelto 
     * se calcula en función de la cantidad de años transcurridos desde fecha de nacimiento del usuario hasta la fecha 
     * de la solicitud. Si aún no se ha definido la fecha de nacimiento del usuario, devuelve un null.
     */
    getAge(){
        if(!this.user.loggedIn) return null;
        let birthdate = new Date(this.user.userBirthdate);
        let today = new Date();
        let age = today.getFullYear() - birthdate.getFullYear();
        let months = today.getMonth() - birthdate.getMonth();
        if(months < 0 || months === 0 && today.getDate() < birthdate.getDate()) age--;
        return age;
    }

    /**
     * Este método devuelve la fecha de nacimiento del usuario del usuario validado o un null si no se ha validado aún. 
     * Si aún no se ha definido la fecha de nacimiento del usuario, devuelve un null.
     */
    getBirthdate(){
        if(!this.user.loggedIn) return null;
        return this.user.userBirthdate;
    }

    /**
     * Este método devuelve el código ISO-2 del idioma por defecto del usuario del usuario validado o el código del idioma por defecto 
     * si no se ha validado aún. Si aún no se ha definido el idioma por defecto del usuario, devuelve un null.
     */
    getLanguage(){
        if(!this.user.loggedIn) return Erp._defLanguage;
        return this.user.userLanguage;
    }
    getLanguages(){
        return [{'code':'es', 'name':'🇪🇸 Español'}, {'code':'en', 'name':'🇬🇧 English'}];
    }
    /**
     * Este método devuelve true si existe un usuario validado y este prefiere el modo nocturno (oscuro) o false si existe un 
     * usuario validado y este prefiere el modo diurno (claro). Si no se ha validado aún devuelve las preferencias del navegador.
     */
    getDarkMode(){
        if(!this.user.loggedIn) {
            const prefersDark = window.matchMedia('(prefers-color-scheme:dark)');
            return prefersDark.matches;
        }
        return this.user.userDarkMode;
    }
    getPrimaryColor(){
        if(!this.user.loggedIn) return null;
        return this.user.userPrimaryColor;
    }
    getTimeZone(){
        if(!this.user.loggedIn) return Erp._defTimeZone;
        return this.user.userTimeZone;
    }

    getSecretCodeTimeout(){
        return Erp._defSecretCodeTimeout;
    }
    setReady(_ready: boolean){
        this.ready = _ready;
    }

    setUser(_user:User){
        console.log('USER:: setUser->', _user);
        this.user = _user;
    }

    getUser() {
        return this.user;
    }

    setUserRole(_role: string) {
        this.user.userRole = _role;
    }

    getUserRole() {
        return this.user.userRole;
    }

    setUserRoleId(_id: number) {
        this.user.userRoleId = _id;
    }

    getUserRoleId() {
        return this.user.userRoleId;
    }

    setUserCode(_code: string) {
        this.user.userCode = _code;
    }

    getUserCode() {
        return this.user.userCode;
    }

    setUserCurrency(_currency: string) {
        this.user.userCurrency = _currency;
    }

    getUserCurrency() {
        return this.user.userCurrency;
    }
    
    setUserEnterprise(_enterprise: string) {
        this.user.userEnterprise = _enterprise;
    }

    getUserEnterprise() {
        return this.user.userEnterprise;
    }

    setUserUrl(_url: string) {
        this.user.userUrl = _url;
    }

    getUserUrl() {
        return this.user.userUrl;
    }

    getLogo(){
        if(!this.user.userUrl || !this.user.userCode) return '/assets/logo.svg';
        return this.user.userUrl+'/images/'+this.user.userCode.toLowerCase()+'/erp_logo.svg';
    }

    getUserProfiles() {
        return this.user.userProfiles;
    }

    setModuleCode(_code:string){
        this.moduleCode = _code;
    }

    getUserPrimaryColor() {
        return this.user.userPrimaryColor;
    }

    setUserPrimaryColor(_color:string){
        this.user.userPrimaryColor = _color;
    }

    setUserTimeZone(_timeZone: string){
        this.user.userTimeZone = _timeZone;
    }

    getModuleCode(){
        return this.moduleCode;
    }

    setSectionCode(_code:string){
        this.sectionCode = _code;
    }

    getSectionCode(){
        return this.sectionCode;
    }

    setIdCode(_code:string){
        this.idCode = _code;
    }

    getIdCode(){
        return this.idCode;
    }

    setModules(_modules:Array<any>){
        this.modules = _modules;
    }

    getModules(){
        return this.modules;
    }

    setInfoModules(_modules:Array<any>){
        this.infoModules = _modules;
    }

    getInfoModules(){
        return this.infoModules;
    }

    setSections(_sections:Array<any>){
        this.sections = _sections;
    }

    getSections(){
        return this.sections;
    }

    setCloseIcon(_icon:string){
        this.closeIcon = _icon;
    }

    getCloseIcon(){
        return this.closeIcon;
    }

    setSectionsVisible(_bool:boolean){
        this.sectionsVisible = _bool;
    }

    getSectionsVisible(){
        return this.sectionsVisible;
    }

    setSectionsLabel(_label:string){
        this.sectionsLabel = _label;
    }

    getSectionsLabel(){
        return this.sectionsLabel;
    }

    setSectionTitle(_title:string){
        this.sectionTitle = _title;
    }

    getSectionTitle(){
        return this.sectionTitle;
    }

    setTableSection(_table:string){
        this.tableSection = _table;
    }

    getTableSection(){
        return this.tableSection;
    }

    
    setSectionSelected(_index:number){
        this.sectionSelected = _index;
    }

    getSectionSelected() {
        return this.sectionSelected;
    }

    getSection() {
        if(this.sectionSelected<0) return null;
        return this.sections[this.sectionSelected];
    }

    setMenuRight(_bool:boolean){
        this.menuRight = _bool;
    }

    getMenuRight() {
        return this.menuRight;
    }

    setModulesVisible(_bool:boolean){
        this.modulesVisible = _bool;
    }

    getModulesVisible() {
        if(!this.user.loggedIn) return false;
        return this.modulesVisible;
    }

    setModulesCollapsed(_bool:boolean){
        this.modulesCollapsed = _bool;
    }

    getModulesCollapsed() {
        return this.modulesCollapsed;
    }

    setModuleSelected(_index:number){
        this.moduleSelected = _index;
    }

    getModuleSelected() {
        return this.moduleSelected;
    }

    getModule() {
        if(this.moduleSelected<0) return null;
        return this.modules[this.moduleSelected];
    }

    setModulesTitle(_title:string){
        this.modulesTitle = _title;
    }

    getModulesTitle(){
        return this.modulesTitle;
    }

    setModuleTitle(_title:string){
        this.moduleTitle = _title;
    }

    getModuleTitle(_lang: string = null){
        if(_lang !== null) this.moduleTitle[_lang];
        return this.moduleTitle;
    }
    
    getImage(_image: Image){
        for(let i of this.images){
            //console.log('ERP :: getImage->', i.name, _image.name, i.enterprise, _image.enterprise, i.type, _image.type, this);
            if(i.name === _image.name && i.enterprise === _image.enterprise && i.type === _image.type) return i.data;
            //if(i.name === _image.name && i.enterprise === _image.enterprise) return i.image;
        }
        return null;
    }

    isImageExist(name:string, enterprise:string, type:string){
        for(let i of this.images){
            //console.log('ERP :: isImageExist->', i.name, name, i.enterprise, enterprise, i.type, type, this);
            if(i.name === name && i.enterprise === enterprise && i.type === type) return true;
        }
        return false;
    }

    isImageDownloaded(name:string, enterprise:string, type:string){
        for(let i of this.images){
            if(i.name === name && i.enterprise === enterprise && i.type === type) return i.downloaded;
        }
        return false;
    }

    addImage(_image: Image){
        if(!this.isImageExist(_image.name, _image.type, _image.enterprise)) this.images.push(_image);
    }

    setImageData(_image: Image){
        for(let i of this.images){
            if(i.name === _image.name && i.enterprise === _image.enterprise && i.type === _image.type) {
                i.data = _image.data;
                i.downloaded = true;
                return true;
            }
        }
        return false;
    }

    getImages(){
        return this.images;
    }

    setModuleDetails(_details:any){
        this.moduleDetails = _details;
    }

    getModuleDetails(){
        return this.moduleDetails;
    }
    
    setNoImage(_noimage: string){
        this.noimage = _noimage;
    }

    getNoImage(){
        return this.noimage;
    }
     
    setNoImageFormat(_format: string){
        this.format = _format;
    }

    getNoImageFormat(){
        return this.format;
    }
}