import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { ErpService } from '../../erp/erp.service';
//import { MenuController } from '@ionic/angular';

@Component({
  selector: 'erp-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
})
export class MenuLeftComponent implements OnInit {

  constructor(/* private menu: MenuController,  */public app: AppComponent, public erp: ErpService) { }

  ngOnInit() {}

/*   closeMenu(menuId) {
    console.log('close menu '+menuId);
    this.menu.close(menuId);
  } */

}
