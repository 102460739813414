import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, NavController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Storage } from '@ionic/storage';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { MenuController, LoadingController } from '@ionic/angular';
import { ErpService } from '../app/erp/erp.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
//import { BackgroundMode } from '@ionic-native/background-mode/ngx';

import { User } from './erp/user';
import { Erp } from './erp/erp';
import { Image } from './erp/image';
import { Observable } from 'rxjs';
import { LoadingService } from './erp/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [ErpService]
})
export class AppComponent implements OnInit {
  //public menuLeft:any;
  public menuRight:any;
  //private module: string;
  //private section: string;
  //private id: string;
  public erp: Erp;
  public darkMode: boolean;
  public isMobile: boolean;
  public isLoading: boolean;
  public activeLang: string;
  public timeZone: string;
  public localIp:Array<number>;
  public localMask:Array<number>;
  public localServer:Array<number>;
  //public loading: any;

  //public user: User;
  //public logo: string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private oneSignal: OneSignal,
    //private backgroundMode: BackgroundMode,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private storage: Storage,
    private barcodeScanner: BarcodeScanner,
    private menu: MenuController,
    private router : Router,
    private translate: TranslateService,
    public loading: LoadingService,
    private erpService: ErpService,
    public navCtrl: NavController,
    private domSanitizer: DomSanitizer,
    private networkInterface: NetworkInterface
  ) {
    //const prefersDark = window.matchMedia('(prefers-color-scheme:dark)');
    //this.darkMode = prefersDark.matches;
    this.erp = new Erp();
    this.isLoading = false;
    this.isMobile = (this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb');
    this.darkMode = this.erp.getDarkMode();
    this.timeZone = this.erp.getTimeZone();
    if(this.darkMode) document.body.classList.add('dark');
    else document.body.classList.add('light');
    this.activeLang = this.erp.getLanguage();
    this.translate.addLangs(['es','en']);
    this.translate.setDefaultLang(this.activeLang);
    // Verifica si el usuario ha iniciado sesión
    this.erpService.getUser().then((_user)=>{
      console.log('### TIPO DE RESULTADO: '+typeof _user, _user);
      if(_user) {
        this.erp.setUser(_user);
        // Cambia el idioma del sistema por el del idioma del usuario
        this.activeLang = _user.userLanguage;
        // Selecciona el modo diurno o nocturno
        this.setDarkMode(_user.userDarkMode);
        // Selecciona el Huso Horario
        this.timeZone = _user.userTimeZone;
        if(this.isMobile){
          this.oneSignal.sendTag('loggedIn', 'Yes');
          this.oneSignal.sendTag('username', _user.username);
          this.oneSignal.sendTag('email', _user.email);
          this.oneSignal.sendTag('userLanguage', _user.userLanguage);
          this.oneSignal.sendTag('userTimeZone', _user.userTimeZone);
        }
        if(_user.loggedIn){
          // Pregunta si el usuario tiene un perfil seleccionado
          if(_user.userRole !== null){
            console.log('ERP :: User is working');
            if(this.isMobile){
              this.oneSignal.sendTag('userRole', _user.userRole);
              this.oneSignal.sendTag('enterprise', _user.userCode);
            }
            // Cambia el color primario de la aplicación
            let primaryColor = (_user.userPrimaryColor !== null)? _user.userPrimaryColor: this.erp.getSystemPrimaryColor();
            document.body.style.setProperty('--ion-color-primary', primaryColor);
            // Carga los módulos que el ususrio está autorizado a usar
            this.erpService.getModules(_user, this.activeLang).subscribe((_modules:any)=>{
              if(_modules !== null) this.erp.setModules(_modules.modules);
              else  this.erp.setModules([]);
              this.erp.setReady(true);
              console.log('ERP :: getModules->', _modules);
              if(this.router.url ==='/') {
                if(this.erp.getUserEnterprise() == null) this.gotoUrl('/profile');
                else this.gotoUrl('/erp');
              }
              if(this.router.url ==='/erp'){
                if(this.erp.getUserEnterprise() == null) this.gotoUrl('/profile');
                else this.selectTop(_user);
              }
              console.log('############### INICIO ################', this.erp.getUserEnterprise(), this.erp);
            });
          }
          else {
            document.body.style.setProperty('--ion-color-primary', this.erp.getSystemPrimaryColor());
            this.gotoProfile();
          }
        }
        else {
          document.body.style.setProperty('--ion-color-primary', this.erp.getSystemPrimaryColor());
          this.gotoLogin();
        }
      }
      else {
        if(this.isMobile) this.oneSignal.sendTag('loggedIn', 'No');
        //this.oneSignal.deleteTags(['username','userRole', 'enterprise', 'userLanguage', 'userTimeZone']);
        document.body.style.setProperty('--ion-color-primary', this.erp.getSystemPrimaryColor());
        console.log('EL USUARIO NO HA INICIADO SESIÓN AÚN!!!', this.router.url);
        this.gotoUrl('/home');
      }
    });
/* 
    this.menuLeft = this.erpService.getLeftMenu(); */
    this.menuRight = this.erpService.getRightMenu();

    this.platform.ready().then(() => {
      if(this.isMobile) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        //if (this.platform.is('cordova')){
        //if (this.platform.is('android')){
/* 
        window["plugins"].OneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
        var notificationOpenedCallback = function(data) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(data));
          let msg = data.payload.body;
          let title = data.payload.title;
          let additionalData = data.payload.additionalData;
          let task = additionalData.task;
          this.showAlert(title, msg, task);
        };
        window["plugins"].OneSignal
        .startInit('56fe5584-5c54-4637-93e9-cbe28b18a9dd', '433162563520')
        .handleNotificationOpened(notificationOpenedCallback)
        //.iOSSettings(iosSettings)
        .inFocusDisplaying(window["plugins"].OneSignal.OSInFocusDisplayOption.Notification)
        .endInit();
        let UUID = window["plugins"].OneSignal.getPermissionSubscriptionState().getSubscriptionStatus().getUserId();
        console.log("UUID:"+UUID);
        */
/*        // Solo en IOS
        window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
          console.log("User accepted notifications: " + accepted);
        }); */

          this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
          this.oneSignal.startInit('56fe5584-5c54-4637-93e9-cbe28b18a9dd', '433162563520');

          this.oneSignal.enableVibrate(true);
          this.oneSignal.enableSound(true);
          //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
          this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
          //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
          
          this.oneSignal.handleNotificationReceived().subscribe( data => {
            console.log('Notification Received: '+JSON.stringify(data));
          // do something when notification is received
            let msg = data.payload.body;
            let title = data.payload.title;
            let additionalData = data.payload.additionalData;
            let task = additionalData.task;
            this.showAlert(title, msg, task);
          });
          
          this.oneSignal.handleNotificationOpened().subscribe( data => {
            console.log('Notification Opened: '+JSON.stringify(data));
            let msg = 'Notification opened';
            let title = 'You already read this before';
            let additionalData = data.notification.payload.additionalData;
            let task = additionalData.task;
            this.showAlert(title, msg, task);
          });
          this.oneSignal.endInit();
          //this.backgroundMode.enable();
        //}
      }
    });
    if(this.platform.is('hybrid')) {
      this.networkInterface.getWiFiIPAddress().then(address => {
        this.localIp = this.formatIp(address.ip);
        this.localMask = this.formatIp(address.subnet);
        console.info(`WiFi IP: ${address.ip}, WiFi Subnet: ${address.subnet}`, JSON.stringify(address));
      }).catch(error => console.error(`Unable to get WiFi IP: ${error}`));
      this.networkInterface.getCarrierIPAddress().then(address => {
        //this.localIp = this.formatIp(address.ip);
        //this.localMask = this.formatIp(address.subnet);
        console.info(`Data IP: ${address.ip}, Data Subnet: ${address.subnet}`, JSON.stringify(address));
      }).catch(error => console.error(`Unable to get Data IP: ${error}`));
    }
    else {
      let address = {ip:'192.168.1.3', subnet:'255.255.255.0'};
      this.localIp = this.formatIp(address.ip);
      this.localMask = this.formatIp(address.subnet);
    }
    //this.localServer = this.findSeverIp();// Busca la dirección del servidor dentro de la red local
  }

  isUrl(url){
    return this.router.url === url;
  }

  ngOnInit() {
  }

  toggleTheme(){
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark');
    document.body.classList.toggle('light');
    let contrast = (this.darkMode)? '#ffffff':'#000000';
    document.body.style.setProperty('--ion-color-medium-contrast', contrast);
  }

  setDarkMode(dark: boolean = true){
    console.log('setDarkMode',dark);
    this.darkMode = dark;
    if(dark){
      document.body.classList.add('dark');
      document.body.classList.remove('light');
      document.body.style.setProperty('--ion-color-medium-contrast', '#ffffff');
    }
    else {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
      document.body.style.setProperty('--ion-color-medium-contrast', '#000000');
    }
  }

  async scanCode(params = null, placeCode = null){
    if(placeCode == null) this.translate.get('erpPlaceCode').subscribe(t=>{ placeCode = t; });
    if(params == null) params = {
      preferFrontCamera : false, // iOS and Android
      showFlipCameraButton : false, // iOS and Android
      showTorchButton : true, // iOS and Android
      torchOn: false, // Android, launch with the torch switched on (if available)       
      prompt : placeCode, // Android
      resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      formats : "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
      orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
      disableAnimations : true, // iOS
      disableSuccessBeep: false // iOS and Android
    };
    return await this.barcodeScanner.scan(params).then(barcodeData => {
      console.log('Barcode data', JSON.stringify(barcodeData));
      return barcodeData;
    }).catch(err => {
      console.log('Error', err);
      return false;
    });
  }
/* 
  async present(msg = null) {
    this.isLoading = true;
    let text = (!msg)? this.pleaseWait: msg;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: text, //'Please wait...',
      //duration: 2000
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
 */
  async selectTop(user) {
    return await new Promise((resolve, reject) => {
      console.log('SelectTop->', JSON.stringify(user));
      this.erp.setSectionsVisible(false);
      this.erp.setSectionTitle('');
      this.erp.setSectionSelected(-1);
      this.erp.setSections([]);
      this.erp.setMenuRight(false);
      this.erp.setModulesVisible(true);
      this.erp.setModuleSelected(-1);
      this.erp.setModuleTitle('');
      this.erp.setModulesCollapsed(false);
      if(user.userCode && user.userRoleId){
        console.log('selectTop => BUSCA LOS MÓDULOS DE ', user, this.erp);
        this.erpService.getModules(user, this.activeLang).subscribe(_modules=>{
          console.log('RESULTADO DE getModules::::', _modules);
          if(_modules !== null) this.erp.setModules(_modules.modules);
          else this.erp.setModules([]);
          //console.error('Cantidad de módulos: '+_modules.length);
          this.erp.setReady(true);
          if(_modules.modules === null) resolve(0);
          else resolve(_modules.modules.length);
          //return _modules.length;
        });
      }
    });
  }

  requestRole(newRole){
    console.log('AppComponent :: requestRole', newRole);

  }

  selectLanguage(lang){
    this.activeLang = lang;
    this.translate.setDefaultLang(lang);
  }

  selectModule(_code){
    console.log('#*#*#* selectModule', _code, this.erp)
    this.getImage(_code,'erp','svg');
/*     if(!this.erp.isImageExist(_code,'svg','erp')) {
      this.erpService.getImage(_code,'svg','erp').then((resp: any)=>{
        //if(resp.response) {
          let image = new Image(_code,'svg','erp', resp, true);
          if(!this.erp.isImageExist(_code,'svg','erp')){
            this.erp.addImage(image);
          }
        //}
        console.log('#*#*#* SectionComponent :: ngOnInit->getImage', resp, this.erp)
      });
    } */
    if(!this.erp.getUser()) this.closeMenu('mainMenu');
    let _modules:Array<any> = this.erp.getModules();
    this.erp.setModuleCode(_code);
    if(_code == null){          // Selecciona el nivel Top del menú
      this.erp.setModuleSelected(-1);
      this.erp.setModulesCollapsed(false);
    }
    else {
      let i = 0;
      for(let _module of _modules){
        if(_module.module === _code){
          this.erp.setModuleSelected(i);
          this.erp.setModulesCollapsed(true);
          this.erp.setModuleTitle(_module.title);
          this.erp.setNoImage(_module.noimage);
          this.erp.setNoImageFormat(_module.format);
          // Busca la imagen de no imagen
          let _ent= this.erp.getUserCode();
          this.getImage(_module.noimage, _ent, _module.format);
          this.erp.setSectionsVisible(true);
          this.erp.setSectionSelected(-1);
          this.loading.present();
          this.erpService.getSections(_code, this.erp.getUser(), this.activeLang).subscribe((_sections:any)=>{
            console.info('}}}}}}}} getSections ->', this.erp.getSections());
            console.log(' # # # # # >> AppComponent:: check module->', _module, _sections.sections, this.erp);
            this.erp.setSections(_sections.sections);
            this.loading.dismiss();
          });
        }
        else i++;
      }
    }
    console.log('AppComponent:: select module ', _modules, _code);

/*     this.menuLeft = this.erpService.selectModule(index); 
    console.log('AppComponent:: select module '+index, this.menuLeft, this.menuRight); */
  }

  selectSection(_code){
    event.preventDefault();
/*     this.menuLeft = this.erp.selectSection(section); */


    //this.erpService.getSections(_code).then((_sections:any)=>{
      
      let _sections = this.erp.getSections();
      console.log(' # # # # # >> AppComponent:: check section->', _code, _sections, this.erp);
      let i=0;
      for(let o of _sections){
        if(o.section === _code){
          this.erp.setSectionCode(_code);
          this.erp.setSectionsVisible(true);
          this.erp.setSectionSelected(i);
          this.erp.setSectionTitle(o.title);
          if(o.elements !== 0) this.erp.setTableSection(o.table);
          else this.erp.setTableSection(null);
          this.gotoUrl(o.url);
          break;
        }
        else i++;
      }

      /* this.erp.setSections(_sections.items);
    }); */
/*     console.log('AppComponent:: select section '+section, this.menuLeft, event);
    return false; */
  }

  userLogout() {
    //this.menuLeft = this.erpService.selectTop();
    this.erpService.logout().then((_user:User)=>{
      this.erp.setUser(_user);
      // Cambia el color primario al color por defecto de la aplicación
      document.body.style.setProperty('--ion-color-primary', this.erp.getSystemPrimaryColor());
      // Cambia el idioma del sistema por el del idioma del usuario
      this.activeLang = this.erp.getSystemLanguage();
      // Selecciona el modo diurno o nocturno
      this.setDarkMode(this.erp.getSystemDarkMode());
      // Selecciona el Huso Horario por defecto
      this.timeZone = this.erp.getSystemTimeZone();
      // Borra los módulos y las secciones
      this.selectTop(_user);
      if(this.isMobile) this.oneSignal.sendTag('loggedIn', 'No');
      //this.oneSignal.deleteTags(['username','userRole', 'enterprise', 'userLanguage', 'userTimeZone']);
      this.gotoUrl('/logout');
    });
    //this.user.loggedIn = false;
    console.log('AppComponent:: user is loged out', this.erp.getUser());
    //this.router.navigate(['/logout'], {replaceUrl: true});
    this.menu.close();
  }

  userLogin(username, password){
    this.loading.present();
    this.erpService.login(username, password, this.activeLang).subscribe(result =>{
    //this.erpService.login(username, password, this.activeLang).then((result) =>{
      console.log('AppComponent:: user is loged in result ->', JSON.stringify(result));
      if(result.response){
        let user = result.user;
        let resp: any = null;
        if(user !== null){
          let avatar = (user.picture !==null && user.format !== null)? new Image('avatar', user.format, user.picture): null;
          if(user.profiles.length === 1){
            resp = new User(true, user.username, user.firstname, user.lastname, user.profiles[0].id, user.profiles[0].role, user.profiles[0].url, user.profiles[0].code, user.profiles[0].enterprise, user.profiles[0].currency, user.profiles, user.profiles[0].primaryColor, user.email, user.mobile, avatar, user.gender, user.birthdate, user.language, user.darkMode!=='0', user.profiles[0].timeZone);
          }
          else {
            let timeZone = (user.profiles.length !== 0)? user.profiles[0].timeZone: null;
            let darkMode = (user.profiles.length !== 0)? user.darkMode!=='0': false;
            resp = new User(true, user.username, user.firstname, user.lastname, 0, null, null,null,null,null,user.profiles,null, user.email, user.mobile, avatar, user.gender, user.birthdate, user.language, darkMode, timeZone);
          }
          this.activeLang = user.language;
          this.erp.setUser(resp);
          // Cambia el idioma del sistema por el del idioma del usuario
          // ESTO DEBE HACERSE LUEGO DE SELECCIONAR EL PERFIL *** OJO ***
          /* // Cambia el Huso Horario del sistema por el del usuario
          this.timeZone = this.erp.getTimeZone(); */
          console.log('Tema del usuario:'+user.darkMode);
          // Selecciona el modo diurno o nocturno según las preferencias del usuario
          this.setDarkMode(user.darkMode != '0');
            if(this.isMobile) {
            this.oneSignal.sendTag('loggedIn', 'Yes');
            this.oneSignal.sendTag('username', user.username);
            this.oneSignal.sendTag('email', user.email);
            this.oneSignal.sendTag('userLanguage', user.userLanguage);
            this.oneSignal.sendTag('userTimeZone', user.userTimeZone);  
          }
          if(user.profiles.length == 1) this.selectProfile(user.profiles[0]);
          this.storage.set('user', resp).then((result: User)=>{
            console.log('ERP Service: Login: '+resp.firstname+' '+resp.lastname, 'Username:'+username, 'Password:'+password, 'Result:', JSON.stringify(result));
            let messages = {'es':'Inicio de sesión satisfactorio', 'en':'Login successful'};
            console.log('✔️ '+messages[this.activeLang]);
            this.loading.dismiss();
            //console.log('Cantidad de Perfiles: '+user.profiles.length);
            //console.log('ERP:', JSON.stringify(this.erp));
            if(user.profiles.length > 1) this.router.navigate(['/profile'], {replaceUrl: true});
            else if(user.profiles.length === 0) this.router.navigate(['/new-role'], {replaceUrl: true});
            else { // solo si el usuario tiene 1 perfil
/*               this.router.navigate(['/erp'], {replaceUrl: true}); */
              this.selectTop(result).then((num)=>{
                console.log('El usuario '+resp.firstname+' '+resp.lastname+' tiene '+num+((num==1)?' perfil.':' perfiles.'));
                let modules = this.erp.getModules();
                this.erp.setReady(true);
                // Cambia el color primario si el nuevo perfil lo tiene definido
                let primaryColor = (this.erp.getPrimaryColor()!==null)? this.erp.getPrimaryColor(): this.erp.getSystemPrimaryColor(); 
                document.body.style.setProperty('--ion-color-primary', primaryColor);
                // Cambia el Huso Horario si el nuevo perfil lo tiene definido
                this.timeZone = (this.erp.getTimeZone()!==null)? this.erp.getTimeZone(): this.erp.getSystemTimeZone(); 
                if(num == 1) {
                  console.error('Módulo: ', modules[0]);
                  this.selectModule(modules[0].code);
                  this.router.navigate([modules[0].url], {replaceUrl: true});
                }
                else this.router.navigate(['/erp'], {replaceUrl: true});
              });
            }
          }).catch((err)=>{
            console.error({response: false, message: 'Catch:'+err, user: new User()})
            this.loading.dismiss();
          });  // Guarda la información del user en el Local Store
        }
      }
      else {
        this.loading.dismiss();
        this.presentErrorMessage(result.message);
      }
/*     }).catch(err=>{
      console.error(err.message);
      this.presentErrorMessage(err.message).then(()=>{
        (<HTMLInputElement>document.getElementById('password')).value = '';
        (<HTMLInputElement>document.getElementById('username')).value = '';
        (<HTMLInputElement>document.getElementById('username')).focus();
      }); */
    });
    this.menu.close();
  }

  userRegister(value, lang: string = null){
    if(!lang) lang = this.activeLang;
/* 
    if(response.response) console.info('✔️ '+response.message);
    else console.error('⚠️ '+response.message);

 */
    this.erpService.register(value, lang).subscribe((result) =>{
      console.log('AppComponent:: user is signed in', value, result);
      if(result.response){
        console.info('✔️ '+result.message);
        console.log('AppComponent:: user is signed in', result.code);
        //this.gotoUrl('/validate');
        this.router.navigate(['/validate/'+value.email], {replaceUrl: true});
      }
      else this.presentErrorMessage(result.message);
    });
    this.menu.close();
  }

  userValidate(value, lang: string = null){
    if(!lang) lang = this.activeLang;
    this.erpService.validate(value, lang).subscribe((result) =>{
      console.log('AppComponent:: user Validate', value, result);
      if(result.response){
        console.info('✔️ '+result.message);
        console.log('AppComponent:: user Validate', result.code);
        this.presentErrorMessage(result.message, 'success');
        this.router.navigate(['/login'], { replaceUrl: true});
      }
      else this.presentErrorMessage(result.message);
    });
    this.menu.close();
  }

  resendCode(value, lang: string = null){
    if(!lang) lang = this.activeLang;
    this.erpService.recovery(value, lang).subscribe((result) =>{
      console.log('AppComponent:: resendCode', value, lang);
      if(result.response){
        console.info('✔️ '+result.message);
        console.log('AppComponent:: resendCode', result.code);
        this.translate.get('secretResent').subscribe(t=>{
          this.presentErrorMessage(t, 'emailSent', 'Ok', 'secret');
        });
        this.router.navigate(['/validate/'+value.email], { replaceUrl: true});
      }
      else this.presentErrorMessage(result.message);
    });

  }

  passwordRecovery(value, lang: string = null){
    if(!lang) lang = this.activeLang;
    this.erpService.recovery(value, lang).subscribe((result) =>{
      console.log('AppComponent:: password recovery', lang, value, result);
      if(result.response){
        console.info('✔️ '+result.message);
        console.log('AppComponent:: password recovery', result.code);
        this.router.navigate(['/validate/'+value.email], { replaceUrl: true});
      }
      else this.presentErrorMessage(result.message);
    });
    this.menu.close();
  }

  userActions(){
    this.presentActionSheet();
  }

  selectProfile(profile){
    console.log('AppComponent:: selectProfile->', profile);
    this.erp.setUserEnterprise(profile.enterprise);
    this.erp.setUserCode(profile.code);

    // Cambia el color primario si el nuevo perfil lo tiene definido
    let primaryColor = (profile.primaryColor)? profile.primaryColor: this.erp.getSystemPrimaryColor();
    document.body.style.setProperty('--ion-color-primary', primaryColor);
    // Cambia el Huso Horario si el nuevo perfil lo tiene definido
    this.timeZone = (profile.timeZone)? profile.timeZone: this.erp.getSystemTimeZone();
    /* // Selecciona el modo diurno o nocturno
    this.setDarkMode(this.erp.getDarkMode()); */
    
    this.erp.setUserCurrency(profile.currency);
    this.erp.setUserRole(profile.role);
    this.erp.setUserRoleId(profile.id);
    this.erp.setUserPrimaryColor(profile.primaryColor);
    this.erp.setUserTimeZone(profile.timeZone);
    this.erp.setUserUrl(profile.url);
    if(this.isMobile) {
      this.oneSignal.sendTag('userLanguage', profile.userLanguage);
      this.oneSignal.sendTag('userTimeZone', profile.userTimeZone);  
      this.oneSignal.sendTag('userRole', profile.role);
      this.oneSignal.sendTag('enterprise', profile.code);
    }
    let user = this.erp.getUser();
    console.log('AppComponent:: selectProfile->selectTop', profile, this.erp, user);
    // Guarda el usuario en la memoria local
    this.selectTop(user).then((num)=>{
      let modules = this.erp.getModules();
      console.log('AppComponent:: selectProfile ', profile, this.erp, user, num);
      if(num == 1) {
        console.error('Módulo: ', modules[0].module);
        this.selectModule(modules[0].module);
        this.router.navigate([modules[0].url], {replaceUrl: true});
      }
      else this.erpService.profile(user, '/erp');
      //this.gotoUrl('/erp');
      //this.user = this.erpService.profile(profile,'/erp');  // OJO transformar a promise -> this.erp.setUser
    });
    this.menu.close();
  }

  closeMenu(menuId) {
    console.log('close menu '+menuId);
    this.menu.close(menuId);
  }
  
  gotoUrl(url:string, params:any=null){
    console.log('AppComponent:: gotoUrl->'+url, params);
    if(params) this.router.navigate([url, params], {replaceUrl: true});
    else  this.router.navigate([url], {replaceUrl: true});
  }

  gotoLogin(){
    this.closeMenu('mainMenu');
    this.gotoUrl('/login');
  }

  gotoSignin(){
    this.closeMenu('mainMenu');
    this.gotoUrl('/register');
  }

  gotoForgot(){
    this.closeMenu('mainMenu');
    this.gotoUrl('/forgot');
  }

  gotoProfile(){
    this.closeMenu('mainMenu');
    this.gotoUrl('/profile');
  }

  gotoMain(){
    this.closeMenu('mainMenu');
    let user = this.erp.getUser();
    this.selectTop(user).then((num)=>{
      let modules = this.erp.getModules();
      console.log('AppComponent:: gotoMain ', this.erp, user, num);
      if(num == 1) {
        console.error('Módulo: ', modules[0].module);
        this.selectModule(modules[0].module);
        this.router.navigate([modules[0].url], {replaceUrl: true});
        //this.gotoUrl(modules[0].url);
      }
      else this.erpService.profile(user, '/erp');
      //else this.gotoUrl('/erp');
      //this.user = this.erpService.profile(profile,'/erp');  // OJO transformar a promise -> this.erp.setUser
    });
  }

  gotoUserInfo(){
    this.closeMenu('mainMenu');
    //alert('Función no implementada aun');
    this.gotoUrl('/user-info');
  }

  gotoNewRole(){
    this.closeMenu('mainMenu');
    //alert('Función no implementada aun');
    this.gotoUrl('/new-role');
  }
  
  gotoStore(){
    this.closeMenu('mainMenu');
    //alert('Función no implementada aun');
    this.gotoUrl('/enter-store');
  }

  getUserProfile(){
    //console.log('AppComponent:: getUserProfile - ', this.erp.getUser());
    return this.erp.getUserRole();
  }

  getUserProfilesQty(){
    return this.erp.getUserProfiles().length;
  }

  getLogo(){
    console.log('AppComponent:: getLogo - ', this.erp.getLogo());
    return this.erp.getLogo();
  }

  getModule(code:string){
    let _module = null;
    let _modules = this.erp.getModules();
    for(let m of _modules){
      if(m.module === code){
        _module = m;
        break;
      }
    }
    console.log('AppComponent:: getModule - ', _module);
    return _module;
  }

  getModuleCode(){
    console.log('AppComponent:: getModuleCode - ', this.erp.getModuleCode());
    return this.erp.getModuleCode();
  }

  getModules(){
    this.erpService.getModules(this.erp.getUser()).subscribe( (result: any) =>{
      if(result.response){
        console.log('AppComponent:: getModules - ', result, this.erp.getModules());
        return result.items;
      }
      else return [];
    })
  }
/* 
  async getSections(code:string){
    console.info('11111111  ->', this.erp.getSections());
    return await this.erpService.getSections(code, this.erp.getUser(), this.activeLang);
  }
 */
  getSections(code:string){
    console.info('11111111 getSections ->', this.erp.getSections());
    return this.erpService.getSections(code, this.erp.getUser(), this.activeLang);
  }

  setModuleCode(module){
    //this.module = module;
    this.erp.setModuleCode(module);
    //console.log('AppComponent:: setModuleCode - ', this.module);
  }

  async getSection(code:string){
    console.info('22222222 getSections ->', this.erp.getSections());
    return await this.erpService.getSections(this.erp.getModuleCode(), this.erp.getUser(), this.activeLang).subscribe((data)=>{
      let _sections = data.sections;
      let _section = null;
        for(let o of _sections){
        if(o.section === code){
          _section = o;
          console.log('AppComponent:: getSection - '+code, _sections, _section);
          return _section;
        }
      }
    });
  }

  async getItems(elements:string, fields:string = '*', where:string = '_', order: string = '_'){
    let enterprise = this.erp.getUserCode();
    let from = 'erp_'+enterprise+'_'+elements;
    console.log('getItems->'+elements, enterprise, fields, from, where, order);
    return await this.erpService.getItemsList(elements, enterprise, fields, from, where, order).subscribe((result: any)=>{
    //if(result.response) this.items = result.items;
      console.log('getItems:: getItemsList.items->'+elements, enterprise, result);
      return result.items;
    });
  }

  getSectionCode(){
    console.log('AppComponent:: getSectionCode - ', this.erp.getSectionCode());
    return this.erp.getSectionCode(); 
  }

  setSectionCode(section){
    //this.section = section;
    this.erp.setSectionCode(section);
    //console.log('AppComponent:: setSectionCode - ', this.section);
  }

  getIdCode(){
    console.log('AppComponent:: getIdCode - ', this.erp.getIdCode());
    return this.erp.getIdCode(); 
  }

  setIdCode(id){
    //this.id = id;
    this.erp.setIdCode(id)
    //console.log('AppComponent:: setIdCode - ', this.id);
  }
  
  getIcon(icon, type = 'svg'){
    let _empCode = this.erp.getUserCode();
    if(!this.erp.isImageExist(icon, _empCode, type)) {
      return this.getImage(icon, _empCode, type);
    }
    else {
      let image = new Image(icon, type, _empCode, null, false);  // Crea la imagen como no descargada
      return this.erp.getImage(image);
    }
  }

  getImage(name: string, enterprise: string, type: string){
    //if(!name) return 'data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 -256 1792 1792"><g transform="matrix(1,0,0,-1,197.42373,1300.6102)"><path d="M 1408,131 Q 1408,11 1335,-58.5 1262,-128 1141,-128 H 267 Q 146,-128 73,-58.5 0,11 0,131 0,184 3.5,234.5 7,285 17.5,343.5 28,402 44,452 q 16,50 43,97.5 27,47.5 62,81 35,33.5 85.5,53.5 50.5,20 111.5,20 9,0 42,-21.5 33,-21.5 74.5,-48 41.5,-26.5 108,-48 Q 637,565 704,565 q 67,0 133.5,21.5 66.5,21.5 108,48 41.5,26.5 74.5,48 33,21.5 42,21.5 61,0 111.5,-20 50.5,-20 85.5,-53.5 35,-33.5 62,-81 27,-47.5 43,-97.5 16,-50 26.5,-108.5 10.5,-58.5 14,-109 Q 1408,184 1408,131 z m -320,893 Q 1088,865 975.5,752.5 863,640 704,640 545,640 432.5,752.5 320,865 320,1024 320,1183 432.5,1295.5 545,1408 704,1408 863,1408 975.5,1295.5 1088,1183 1088,1024 z"/></g></svg>';
    console.log('<><><><><> AppComponent :: getImage ->', name, enterprise, type);
    if(name === null || type == null) {
      let _noimg = this.erp.getNoImage();
      let _noimgfmt = this.erp.getNoImageFormat();
      if(_noimg == null || _noimgfmt == null || !this.erp.isImageExist(_noimg, enterprise, _noimgfmt)) return '../assets/images/unknown.svg';
      return this.getImage(_noimg, enterprise, _noimgfmt);
    }
    let image = new Image(name, type, enterprise, null, false);  // Crea la imagen como no descargada
    if(!this.erp.isImageExist(name, enterprise, type)){
      this.erp.addImage(image);  // Agrega la imagen y espera que el servidor responda
      /* if(type == 'flag'){
        // busca la imagen en el servidor
        this.erpService.getFlag(name, this.activeLang).subscribe((resp: any)=>{
          console.log('AppComponent :: getFlag ->', resp);
          if(!resp) return null;
          image.data = this.domSanitizer.bypassSecurityTrustUrl('data:image/svg+xml;utf8,'+resp.image);
          this.erp.setImageData(image);
          console.log('AppComponent :: ngOnInit ->addImage', image, this.erp);
          return image.data;
        });
      }
      else { */
        // busca la imagen en el servidor
        this.erpService.getImage(name, type, enterprise, this.activeLang).subscribe((resp: any)=>{
          console.log('AppComponent :: getImage ->', resp);
          if(!resp) return null;
          //_image = this.sanitizer.bypassSecurityTrustUrl('data:image/svg+xml;utf8,'+resp);
          switch(type){
            case 'flag': 
            //case 'svg': image.data = 'data:image/svg+xml;utf8,'+resp.image.replace('\\',''); break;
            case 'svg': image.data = 'data:image/svg+xml;utf8,'+resp.image; break;
            //case 'flag': image.data = this.domSanitizer.bypassSecurityTrustUrl('data:image/svg+xml;utf8,'+resp.image); break;
            case 'jpeg': image.data = 'data:image/jpeg;base64,'+ resp.image; break;
            case 'png': image.data = 'data:image/png;base64,'+ resp.image; break;
            //default: image.data = btoa(resp); break;
          }
          this.erp.setImageData(image);
          console.log('AppComponent :: ngOnInit ->addImage', image, this.erp);
          return image.data;
        });
      /* } */
    }
    else return this.erp.getImage(image);
  }

  async presentActionSheet() {
    let _title = '';
    let _subtitle = '';
    let _msg = [];
    let _buttons = [];
    this.translate.get(['erpEnterStore', 'erpNoProfile','erpRequestNewProfile', 'erpChangeProfile','erpSignInButton','erpUserSettings','erpLogOut','erpNoLogIn','erpLogIn',
        'erpPasswordRecovery','erpGotoHome','erpChangeDark','erpChangeLight','Ok', 'Dismiss']).subscribe(t => { _msg = t});

    if((this.erp.isLoggedIn())){
      _title = this.erp.getName(); 
      if(this.router.url !=='/enter-store'){
        _buttons.push({
          text: _msg['erpEnterStore'], 
          icon: 'qr-code', 
          handler: () => { this.gotoStore() } 
        });
      }
      if(this.erp.isWorking()) _subtitle = this.erp.getUserRole()+' - '+this.erp.getUserEnterprise();
      else _subtitle = _msg['erpNoProfile'];
      if(this.router.url !=='/profile'){
        if(this.router.url !=='/new-role'){
          if(this.erp.getUserProfiles().length === 1){
            _buttons.push({
              text: _msg['erpRequestNewProfile'], 
              icon: 'person-add', 
              handler: () => { this.gotoNewRole() } 
            });
          }
          else {
            _buttons.push({
              text: _msg['erpChangeProfile'], 
              icon: 'trail-sign', 
              handler: () => { this.gotoProfile() } 
            });
          }
        }
        else if(this.erp.getUserRole() === 'demo'){
          _buttons.push({
            text:  _msg['erpSignInButton'], 
            icon: 'person-add', 
            handler: () => { this.gotoSignin() } 
          });  
        }
      }
      else {    // Selección del perfil (/profile)
        _buttons.push({
          text:  _msg['erpRequestNewProfile'], 
          icon: 'shirt', 
          handler: () => { this.gotoNewRole() } 
        });   
      }
      if(this.router.url !=='/user-info') _buttons.push({
        text: _msg['erpUserSettings'], 
        icon: 'person-circle', 
        handler: () => { this.gotoUserInfo() } 
      });  
      _buttons.push({
        text: _msg['erpLogOut'],
        icon: 'log-out',
        handler: () => {
          this.presentAlertConfirm();
          console.log('Close session clicked');
        }
      });
    }
    else {
      _title = _msg['erpNoLogIn'];
      _subtitle = _msg['erpNoProfile'];
      if(this.router.url !=='/login'){
        _buttons.push({
          text: _msg['erpLogIn'], 
          icon: 'log-in', 
          handler: () => { this.gotoLogin() } 
        });
      }
      else {
        _buttons.push({
          text: _msg['erpPasswordRecovery'], 
          icon: 'key', 
          handler: () => { this.gotoForgot() } 
        });   
      }
      if(this.router.url !=='/register'){
        _buttons.push({
          text: _msg['erpSignInButton'],
          icon: 'person-circle', 
          handler: () => { this.gotoSignin() } 
        });
      }
    }
    if(this.router.url !=='/erp'){
      _buttons.push({
        text: _msg['erpGotoHome'],
        icon: 'home', 
        handler: () => { this.gotoMain() } 
      });
    }
    if(this.darkMode){
      _buttons.push({
        text: _msg['erpChangeLight'], 
        icon: 'sunny', 
        handler: () => { this.toggleTheme() } 
      });
    }
    else {
      _buttons.push({
        text: _msg['erpChangeDark'], 
        icon: 'moon', 
        handler: () => { this.toggleTheme() } 
      });
    }

    _buttons.push({
      text: _msg['Dismiss'], 
      icon: 'close',
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      }
    });
    const actionSheet = await this.actionSheetController.create({
      header: _title,
      subHeader: _subtitle,
      buttons:  _buttons
    });
    await actionSheet.present();
  }

  async showAlert(title, msg, task = null) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {

          }
        }
      ]
    });
    alert.present();
}
/* 
  async presentSuccessMessage(message, btnOk = null) {
    let header = '';
    this.translate.get('success').subscribe(t=>{header = t});
    if(btnOk === null){
      this.translate.get('Ok').subscribe(t=>{btnOk = t});
    }
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [{ role: 'cancel', text: btnOk }]
    });
    await alert.present();
  } */

  async presentErrorMessage(message, header='error', btnOk = null, focusOk = null) {
    console.error('⚠️ '+message);
    this.translate.get(header).subscribe(t=>{header = t});
    if(btnOk === null){
      this.translate.get('Ok').subscribe(t=>{btnOk = t});
    }
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [{ role: 'cancel', text: btnOk,
        handler: () => {
          if(focusOk !== null) document.getElementById(focusOk).focus();
          console.log('Focus in '+focusOk);
        } 
      }]
    });
    await alert.present();
  }

  async presentAlertConfirm() {
    let header: string = '';
    let title: string = '';
    let btnOk: string = '';
    let btnCancel: string = '';
    this.translate.get(['erpLogOut','erpLogOutMessage','Ok', 'Dismiss']).subscribe(t => {
      header = t['erpLogOut'];
      title = t['erpLogOutMessage'];
      btnOk = t['Ok'];
      btnCancel = t['Dismiss'];
    });
    const alert = await this.alertController.create({
      header: header,
      message: title,
      buttons: [
        {
          text: btnCancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: btnOk,
          handler: () => {
            console.log('Confirm Okay');
            this.userLogout();
          }
        }
      ]
    });

    await alert.present();
  }

  getText(obj){
    //console.log('getText->'+this.activeLang,obj);
    if(typeof obj === 'string') return obj;
    if(obj === null) return '';
    return obj[this.activeLang];
  }

  isEmailValid(email){
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/.test(email);
  }
  
  findSeverIp(){
    let mask = this.localMask;
    let ip = this.localIp;
    let net = [];
    let hosts = 0;
    for(let n=0; n<mask.length; n++){
      console.log(mask[n], 255-mask[n], hosts);
      hosts = hosts*256 + (255-mask[n]);
      net[n] = ip[n] & mask[n];
    }
    let testIp = net;
    let h=1;
    let test = true;
/*     while(h<hosts-1){
      if(test){
        test = false;
        // Verifica la IP
        testIp = this.sumaIp(testIp, 1);
        this.erpService.testServer(testIp).then((data)=>{
          test = true;
          console.log(data);
          if( (''+data).indexOf('API ERP System V')) {
            h=hosts;
          }
          h++;
        }).catch(e=>{
          test = true; 
          console.error(e);
          h=hosts;
        });
        //console.log();
        console.log(testIp);
      }
    } */
    console.log(ip, mask, net, hosts);
    return [0,0,0,0];
  }

  formatIp(address:any){
    let ip:Array<number> = [0,0,0,0];
    let nibbles = address.split('.');
    for(let i = 0; i < nibbles.length; i++){
      ip[i] = parseInt(nibbles[i],10);
    }
    return ip;
  }

  sumaIp(testIp, n){
    for(let i = testIp.length-1; i >= 0; i--){
      testIp[i] = (parseInt(testIp[i],10) + parseInt(''+n%256, 10));
      n /= 256;
    }
    return testIp;
  }
}
