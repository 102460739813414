import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public isLoading = false;

    constructor(private translate: TranslateService, public loadingController: LoadingController) {
    }

    async present(msg = null) {
        if(msg === null){
            this.translate.get('pleaseWait').subscribe(t=>{msg = t});
        }
        this.isLoading = true;
        return await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: msg, //'Please wait...
            // duration: 5000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async dismiss() {
        this.isLoading = false;
        return await this.loadingController.dismiss().then(() => console.log('dismissed'));
    }
}