import { Role } from './role';
import { Image } from './image';

export class User{

    constructor(
        public loggedIn: boolean = false,
        public username: string = null,
        public firstname: string = null,
        public lastname: string = null,
        public userRoleId: number = 0,
        public userRole: string = null,
        public userUrl: string = null,
        public userCode: string = null,
        public userEnterprise: string = null,
        public userCurrency: string = null,
        public userProfiles: Array<Role> = [],
        public userPrimaryColor: string = null,
        public userEmail: string = null,
        public userMobile: string = null,
        public userPicture: Image = null,
        public userGender: string = null,
        public userBirthdate: Date = null,
        public userLanguage: string = null,
        public userDarkMode: boolean = false,
        public userTimeZone: string = null
    ) {
        
    }

    getProfiles() {
        return this.userProfiles;
    }

    setProfile(profile){
        console.log(' ----> setProfile=>', profile);
        this.userRole = profile.role;
        this.userCurrency = profile.currency;
        this.userEnterprise = profile.enterprise;
        this.userUrl = profile.url;
        this.userCode = profile.code;
        return this;
    }

    getLogo(){
        let logo = (!this.userUrl || !this.userCode)? 'assets/logo.svg': this.userUrl+'/logo/'+this.userCode+'/logo.svg';
        console.log('User:: getLogo - ', logo);
        return logo;
    }
    
}