import { SafeResourceUrl } from '@angular/platform-browser';

export class Image{

    constructor(
        public name: string = '',
        public type: string = '',
        public enterprise: string = '',
        public data: SafeResourceUrl = null,
        public downloaded: boolean = false
    ) {
        
    }
/* 
    getName(){
        return this.name;
    }

    getType(){
        return this.type;
    }

    getData(){
        return this.data;
    }

    getDownloaded(){
        return this.downloaded;
    }

    getEnterprise(){
        return this.enterprise;
    } */
}