import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { from } from 'rxjs';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'erp',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'enter-store',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'modules',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'module-details/:module',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'new-role',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'new-enterprise',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'user-info',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  /* {
    path: 'validate',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  }, */
  {
    path: 'validate/:email',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'erp/product/:shop/:sku',
    loadChildren: () => import('./erp/product/product.module').then( m => m.ProductPageModule)
  },
  {
    path: 'erp/:module',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'erp/:module/:section',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: 'erp/:module/:section/:page',
    loadChildren: () => import('./erp/erp.module').then( m => m.ErpPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
]
;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
