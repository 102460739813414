import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AutoCompleteModule } from 'ionic4-auto-complete';
//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage';
import { HTTP } from '@ionic-native/http/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { MenuLeftComponent } from './erp/menu-left/menu-left.component';
import { MenuRightComponent } from './erp/menu-right/menu-right.component';

import { AppRoutingModule } from './app-routing.module';
import { ErpService } from '../app/erp/erp.service';

import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
//import { AgmCoreModule } from '@agm/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LoadingService } from './erp/loading.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MenuLeftComponent,
    MenuRightComponent
  ],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    AutoCompleteModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })/* ,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtWd_KM8gaEislL7nz89FBIMttou2wb9c'
    }) */
  ],
  providers: [
    BarcodeScanner,
    ErpService,
    Geolocation,
    HTTP,
    LoadingService,
    NetworkInterface,
    OneSignal,
    SplashScreen,
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
