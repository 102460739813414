import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'erp-menu-right',
  templateUrl: './menu-right.component.html',
  styleUrls: ['./menu-right.component.scss'],
})
export class MenuRightComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
